<template>
  <v-form @submit.prevent="submit()" ref="loginForm">
    <v-text-field
      type="email"
      v-model="form.email"
      label="E-mail"
      :rules="emailRules"
      :dark="true"
      required
    ></v-text-field>

    <v-text-field
      type="password"
      :dark="true"
      v-model="form.password"
      label="Mot de passe"
      :rules="passwordRules"
      :disabled="submitting"
      required
      autocomplete
    ></v-text-field>

    <v-layout align-center justify-center column>
      <v-btn
        type="submit"
        class="mb-2 py-4"
        color="indigo"
        block
        x-small
        @click="submit()"
        :disabled="!isFormValid || submitting"
        >Je présente ma carte d'électeur</v-btn
      >

      <v-btn
        class="my-1"
        :dark="!submitting"
        x-small
        block
        :to="{ name: 'SignUp' }"
        :disabled="submitting"
        >Je crée ma carte d'électeur tout de
        suite</v-btn
      >

      <v-btn
        :to="{ name: 'PasswordRecovery' }"
        x-small
        block
        class="ma-0"
        :disabled="submitting"
        >Mot de passe oublié ?</v-btn
      >
    </v-layout>
  </v-form>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      submitting: false,
      emailRules: [
        (v) => !!v || "E-mail est requis.",
        (v) => /.+@.+\..+/.test(v) || "L'E-mail doit être valide.",
      ],
      passwordRules: [
        (v) => !!v || "Entrer un mot de passe",
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit être au minimum de 6 caractères.",
      ],
      form: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    isFormValid() {
      return this.form.email && this.form.password;
    },
  },

  methods: {
    async submit() {
      if (this.$refs.loginForm.validate()) {
        // if (valid) {
        this.submitting = true;

        let user = await this.$store.dispatch("user/loginUser", {
          email: this.form.email,
          password: this.form.password,
        });

        if (user) {
          // console.log(
          //   "sessionStorage.getItem(redirectPath)",
          //   sessionStorage.getItem("redirectPath"),
          //   sessionStorage.getItem("redirectPath") !== null
          // );
          if (
            sessionStorage.getItem("redirectPath") == "" ||
            sessionStorage.getItem("redirectPath") == null
          ) {
            this.$router.push({ name: "MyAccount" }).catch((err) => {
              if (
                err.name !== "NavigationDuplicated" &&
                !err.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                // But print any other errors to the console
                // console.log(err);
              }
            });
          } else {
            // console.log(
            //   "on rentre ici catr il y a qqch dans la session",
            //   sessionStorage.getItem("redirectPath")
            // );
            this.$router
              .push({ path: sessionStorage.getItem("redirectPath") })
              .catch((err) => {
                // console.log("rerr nam", err.name);
                if (
                  err.name !== "NavigationDuplicated"
                  // &&
                  // !err.message.includes(
                  //   "Avoided redundant navigation to current location"
                  // )
                ) {
                  // console.log("erreur", err);
                }
              });
          }        
        } else {
          this.submitting = false;
          this.form.password = "";
        }
      }
      //});
    },
  },
};
</script>
