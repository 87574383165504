<template>
  <v-container text-xs-center>
    <v-layout align-center justify-center>
      <v-row class="align-center justify-center" no-gutters>
        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
          <h1 class="text-center">Ma carte d'électeur Demokratio</h1>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout align-center justify-center row>
      <v-row class="align-center justify-center mx-1 px-1" no-gutters>
        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
          <router-view />
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Authentication",
};
</script>
