<template>
  <v-form @submit.prevent="submit()">
    <v-text-field
      :disabled="submitting"
      type="email"
      v-model="form.email"
      :rules="emailRules"
      label="E-mail"
      :dark="true"
      required
    ></v-text-field>
    <v-layout align-center justify-center column fill-height>
      <v-btn
        type="submit"
        color="primary"
        block
        x-small
        class="mb-1"
        @click="submit()"
        :disabled="!isFormValid || submitting"
        >Réinitialisation de mot de passe</v-btn
      >

      <v-btn
        block
        x-small
        class="ma-0"
        :to="{ name: 'Login' }"
        :disabled="submitting"
        >Retour</v-btn
      >
    </v-layout>
  </v-form>
</template>

<script>
// import { Auth } from "@/firebase/auth";
let A;
if(process.env.VUE_APP_MODE == 'production') {
  //import { Auth } from '@/firebase/production/auth'; 
  A = require('@/firebase/production/auth.js');
}
else {
  A = require('@/firebase/development/auth.js');
}
const Auth = A.Auth
// import { Auth } from `@/firebase/${process.env.VUE_APP_MODE}/auth`; 
import { mapMutations } from "vuex";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      submitting: false,
      emailRules: [
        (v) => !!v || "E-mail est requis.",
        (v) => /.+@.+\..+/.test(v) || "L'E-mail doit être valide.",
      ],
      dictionary: {
        attributes: {
          email: "e-mail address",
        },
      },
      form: {
        email: "",
      },
    };
  },

  computed: {
    isFormValid() {
      return this.form.email;
    },
  },

  methods: {
    ...mapMutations(["HIDE_SNACKBAR", "SHOW_SNACKBAR"]),
    submit() {
      // this.$validator.validate().then((valid) => {
      if (this.isFormValid) {
        this.submitting = true;
        this.HIDE_SNACKBAR();

        Auth.sendPasswordResetEmail(this.form.email)
          .then((response) => {
            this.submitting = false;
            this.form.email = "";
            this.SHOW_SNACKBAR({
              text: "La réinitialisation de votre de mot de passe a été envoyée par mail.",
            });
          })
          .catch((error) => {
            this.submitting = false;
            this.SHOW_SNACKBAR(error.message);
          });
      }
      // });
    },
  },
};
</script>
