<template>
  <div>
    <v-form @submit.prevent="submit()" ref="signupForm">
      <VueHcaptcha
        ref="invisibleHcaptcha"
        sitekey="08757480-cf4b-443f-8557-4c2cdc0cfb36"
        size="invisible"
        theme="dark"
        @verify="onCallback"
        @expired="onExpire"
      />
      <v-text-field
        type="name"
        v-model="form.name"
        :rules="nameRules"
        label="Nom (Tout le monde s'appelle Bob sur demokrat.io : vous resterez anonyme) "
        :dark="true"
        required
        :readonly="true"
      ></v-text-field>

      <v-text-field
        type="email"
        v-model="form.email"
        :rules="emailRules"
        placeholder="Préférer un mail avec pseudonyme pour éviter l'identification"
        label="E-mail"
        :dark="true"
        required
      ></v-text-field>

      <v-text-field
        type="password"
        :dark="true"
        v-model="form.password"
        :rules="passwordRules"
        label="Mot de passe"
        :disabled="submitting"
        required
        autocomplete
      ></v-text-field>

      <v-text-field
        type="password"
        :dark="true"
        v-model="form.password_confirmation"
        :rules="passwordConfirmationRules"
        label="Confirmation du mot de passe"
        :disabled="submitting"
        required
        autocomplete
      ></v-text-field>

      <v-btn
        type="submit"
        color="primary"
        block
        x-small
        class="mb-1"
        @click="submit()"
        :disabled="!isFormValid || submitting"
        >Créer un nouvel électeur</v-btn
      >
      <v-btn
        :to="{ name: 'Login' }"
        color="indigo"
        :dark="!submitting"
        block
        class="mb-1"
        x-small
        :disabled="submitting"
        >Déjà électeur ?</v-btn
      >
      <v-btn color="success" block x-small @click="dialog = true"
        >1ère visite ? Bienvenue</v-btn
      >
    </v-form>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center">Bienvenue</v-card-title>
        <v-card-text class="text-center">
          Demokratio est un jeu sérieux<br />
          Le jeu le plus sérieux du monde<br />
          Votre avis compte à chaque fois<br />
          que vous voterez : <br />
          vous changez le monde<br />
          et le monde le verra bientôt.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=""
            text
            @click="
              dialog = false;
              goToFaq();
            "
          >
            Les règles du jeu
          </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">
            c'est parti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
// import EmailValidation from "emailvalid";

export default {
  name: "SignUp",
  components: { VueHcaptcha },
  data() {
    // const ev = new EmailValidation({ allowFreemail: true });
    return {
      dialog: false,
      verified: false,
      submitting: false,
      nameRules: [
        (v) => !!v || "Le nom est requis.",
        // (v) => (v && v.length >= 3) || "pass must be less than 3 characters",
        (v) =>
          v == "Bob" ||
          "Tout le monde s'appelle Bob sur demokrat.io : vous resterez anonyme",
      ],
      emailRules: [
        (v) => !!v || "Un e-mail est requis.",
        (v) => /.+@.+\..+/.test(v) || "L'e-mail doit être valide.",
        // (v) => ev.check(v).valid || "Cet e-mail ne convient pas.",
      ],
      passwordRules: [
        (v) => !!v || "Le mot de passe est requis",
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit être au minimum de 6 caractères.",
      ],
      passwordConfirmationRules: [
        (v) => !!v || "Le mot de passe de confirmation est requis",
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit être au minimum de 6 caractères.",
        (v) => v == this.form.password || "Le mot de passe doit être le même.",
      ],
      form: {
        name: "Bob",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },

  computed: {
    isFormValid() {
      return (
        this.form.name &&
        this.form.email &&
        this.form.password &&
        this.form.password_confirmation
      );
    },
    ...mapState(["currentUser"]),
  },

  methods: {
    goToFaq() {
      this.$store.commit("openFaq", true);
    },
    onExpire() {
      this.verified = false;
      console.log("Expired");
    },
    onError(err) {
      console.log(`Error: ${err}`);
    },

    async submit() {
      this.$refs.invisibleHcaptcha.execute();
    },

    redirectToAccount() {
      this.$router
        .push({
          name: "MyAccount",
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          // if (
          //   err.name !== "NavigationDuplicated" &&
          //   !err.message.includes(
          //     "Avoided redundant navigation to current location"
          //   )
          // ) {
          //   // But print any other errors to the console
          console.log(err);
          // }
        });
    },

    async onCallback(response) {
      this.verified = true;
      // console.log(`Callback Response: ${response}`);
      //                       },
      // async submit() {
      // let valid = await this.$refs.signupForm.validate();

      // if (!valid) {
      //   return false;
      // }

      this.submitting = true;

      let user = await this.$store.dispatch("user/createUser", {
        email: this.form.email,
        password: this.form.password,
        displayName: "Bob",
      });

      if (user) {
        this.redirectToAccount();
        // this.dialog = true;
      } else {
        this.submitting = false;
        // this.form.password = "";
        // this.form.password_confirmation = "";
      }
    },
  },
};
</script>
